<template>
    <div  class="global-content">
        <div class="block global-margin-bottom global-shadow">
            <div><i class="fa fa-arrow-right global-title-fa"></i><span class="global-title">请选择您要操作的设备</span><span class="global-remark">（多选）</span></div>
            <van-divider />
            <div class="handle" v-if="devData.length != 0">
                <van-checkbox-group class="box-container" v-model="result" direction="horizontal" ref="checkboxGroup">
                    <van-col span="8" v-for="(item,index) in devData" :key="index">
                        <van-checkbox checked-color="#0957ff" :name="item.devCode" >{{item.devName}}</van-checkbox>
                    </van-col>
                </van-checkbox-group>
                <van-divider style="margin: 1% 0 5% 0" />
                <div class="layout">
                    <van-button color="#0957ff" round class="m-right" size="small" @click="checkAll">全选</van-button>
                    <van-button color="#0957ff" round size="small"  @click="toggleAll">反选</van-button>
                </div>
            </div>
            <div v-else class="empty-hint">{{hintText}}</div>
        </div>
        <div class="block global-shadow">
            <div><i class="fa fa-lightbulb-o global-title-fa-big"></i><span class="global-title">照明控制</span></div>
            <van-divider />
            <div class="info">
                <van-row>
                    <van-col class="line-block" span="12" v-for="(item,i) in lightData" :key="item.itemCode" @click="selectItem(item.itemCode,i)" :class="configItemIndex == i? 'active':'' ">
                        <div><i class="fa fa-th-large"></i><span class="t-right" v-text="item.itemName"></span></div>
                    </van-col>
                </van-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'illumination',
    data(){
        return{
            devData:[],
            lightData:[],
            configItemIndex:0,
            result: [],
            isAisle:true,
            hintText:'加载中...'
        }
    },
    mounted(){
        const projectCode = window.sessionStorage.getItem('projectCode');
        // 获取所有设备信息
        this.$api.ITGS.getAllDevInfo({
            projectCode:projectCode,
            controlDevLight:1
        }).then((data)=>{
            if(Array.isArray(data)){
                data.length == 0 ?  this.hintText='具备功能的设备列表为空' : this.devData = data;        
            }
        })
        // 获取灯光控制选项
        this.$api.ITGS.getControlDict({typeCode:'control_light'}).then((data)=>{
            this.lightData = data;
        })
    },
    methods:{
        //全选
        checkAll() {this.$refs.checkboxGroup.toggleAll(true);},
        //反选
        toggleAll() {this.$refs.checkboxGroup.toggleAll();},
        // 多个设备控制
        selectItem(code,i){
            if(this.result.length == 0){
                this.$toast('请先选择要操作的设备');
                return false;
            }
            if(this.isAisle){
                this.configItemIndex =i;
                this.isAisle = false;
                this.$api.ITGS.subLightBatch({
                    devCode:this.result,
                    controlModel:code
                }).then((data)=>{
                    this.$toast('照明控制指令已下发');
                }) 
                const timeLimit = setTimeout(()=>{
                    this.isAisle = true;
                    clearTimeout(timeLimit)
                },5000)
            }else{
                this.$toast('操作频率过快，请在5秒之后再试')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.block{
    background-color: #fff;
    padding: 2%;
    border-radius: 10px;
    .handle{
        padding: 0 4% 4% 4%;
        font-size: 0.95rem;
        .box-container{min-height:4rem;max-height:9.8rem;overflow-x: hidden;overflow-y: scroll;}
        .van-col{margin-bottom: 6%;}
        .layout{
            text-align: right;
            .m-right{margin-right:10%}
        }
    }
    .info{
        color:#656565;
        text-align: center;
        .active{color: #0957ff;}
        .line-block{text-align: left;padding-left: 5%;}
        .van-col{margin-bottom: 8%;}
        .fa{font-size: 1.5rem;vertical-align:middle}
        .t-right{line-height: 2;padding-left: 8px;font-size: .85rem;}
    }
}
</style>